<template>
  <div
    data-test="questions-form"
    class="provider-question"
    :style="{ '--mainColor': color.main }"
  >
    <div class="provider-question__wrap d-flex">
      <div class="provider-question__img">
        <img
          class="relative hidden"
          loading="lazy"
          :src="
            imgUrl(
              'https://api.dominter.net/media/uploads/blog/questions.png',
              600,
              0,
            )
          "
          alt="helper"
        >
      </div>
      <div
        class="provider-question__form flex-grow d-flex"
        :style="'transform: translateX(' + (nextStep ? '-100%' : '0') + ');'"
      >
        <div class="provider-question__step1 d-flex flex-column w-100">
          <p class="provider-question__title headline-3">
            Остались вопросы?
          </p>
          <span class="title-2 gray-dark--text">Выберите вариант</span>
          <div class="provider-question__radio title-5 d-flex">
            <button
              data-test="questions-form-connection-category-button"
              :class="{ active: radio === 'connect' }"
              @click="setRadio('connect')"
            >
              Подключение
            </button>
            <button
              data-test="questions-form-support-category-button"
              :class="{ active: radio === 'support' }"
              @click="setRadio('support')"
            >
              Поддержка
            </button>
            <button
              data-test="questions-form-other-category-button"
              :class="{ active: radio === 'other' }"
              @click="setRadio('other')"
            >
              Другое
            </button>
          </div>
          <div
            v-show="radio === 'connect'"
            class="provider-question__value d-flex flex-column"
          >
            <form
              action="#"
              autocomplete="off"
              class="d-flex flex-column provider-question__data flex-grow"
              @submit.prevent="submit"
            >
              <div class="provider-question__house-type d-flex">
                <p
                  class="provider-question__chbx"
                  :class="{ active: hType === 'FLAT' }"
                  @click="hType = 'FLAT'"
                >
                  <span
                    class="white--text d-flex"
                    :class="hType === 'FLAT' ? 'primary--bg' : 'white--bg'"
                  >
                    <span
                      class="mask-icon mask-size-lg mask-ischecked"
                    /></span>Квартира
                </p>
                <p
                  class="provider-question__chbx"
                  :class="{ active: hType === 'HOUSE' }"
                  @click="hType = 'HOUSE'"
                >
                  <span
                    class="white--text d-flex"
                    :class="hType === 'HOUSE' ? 'primary--bg' : 'white--bg'"
                  >
                    <span
                      class="mask-icon mask-size-lg mask-ischecked"
                    /></span>Частный дом
                </p>
                <p
                  class="provider-question__chbx"
                  :class="{ active: hType === 'OFFICE' }"
                  @click="hType = 'OFFICE'"
                >
                  <span
                    class="white--text d-flex"
                    :class="hType === 'OFFICE' ? 'primary--bg' : 'white--bg'"
                  >
                    <span
                      class="mask-icon mask-size-lg mask-ischecked"
                    /></span>Офис
                </p>
              </div>
              <div class="provider-question__inputs d-flex flex-wrap align-end">
                <div class="provider-question__input">
                  <p class="provider-question__label title-5 gray-b--text">
                    Введите телефон
                  </p>
                  <LazyVPhoneInput
                    ref="dPhone"
                    v-model:value="phoneNumber"
                    class="w-100"
                    @validate="checkPhoneValidation($event)"
                  />
                </div>
                <div class="provider-question__input">
                  <p class="provider-question__label title-5 gray-b--text">
                    Введите имя
                  </p>
                  <LazyVTextInput
                    ref="dName"
                    v-model:value="clientName"
                    class="w-100"
                    :validation-length="0"
                    enterkeyhint="send"
                    tabindex="2"
                  />
                </div>
                <d-btn
                  :loading="loading"
                  :disabled="!isValidPhone || !isValidName || !hType"
                  class="w-100 provider-question__submit title-4"
                  color="custom"
                  size="medium"
                  @click.prevent="submit"
                >
                  Жду звонка
                </d-btn>
                <LazyVMenu
                  content-class="white--bg"
                  @update:model-value="showActiveSortList"
                >
                  <template #activator="{ props }">
                    <span
                      v-bind="props"
                      class="provider-question__menu pointer d-flex align-center gray-b--text title-5"
                    >
                      Перезвоним {{ timeToCall }}
                      <img
                        :class="{ rotate: props['aria-expanded'] === 'true' }"
                        style="transition: transform 0.24s; width: 20px; height: 20px;"
                        loading="lazy"
                        src="@/assets/images/svg/arrow-expand.svg"
                        alt="arrow"
                      >
                    </span>
                  </template>
                  <LazyVList elevation="4">
                    <LazyVListItem
                      :value="timeNow"
                      :disabled="timeNow.text === timeToCall"
                      @click="setTimeToCall(timeNow)"
                    >
                      <span class="subhead-3">{{ timeNow.text }}</span>
                    </LazyVListItem>
                    <LazyVListItem
                      v-for="item in timeList"
                      :key="item.val"
                      :disabled="item.text === timeToCall"
                      @click="setTimeToCall(item)"
                    >
                      <span class="subhead-3">{{ item.text }}</span>
                    </LazyVListItem>
                  </LazyVList>
                </LazyVMenu>
              </div>
              <p class="subhead-6">
                <span class="">Отправляя заявку, вы соглашаетесь</span> с
                <button
                  class="secondary-dark--text"
                  @click="goAway('/legal/privacy/')"
                >
                  политикой обработки данных
                </button>
              </p>

              <input
                style="opacity: 0; max-height: 0"
                type="submit"
              >

              <div class="provider-question__actions d-flex" />
            </form>
          </div>
          <div
            v-show="radio === 'support'"
            class="provider-question__value d-flex flex-column"
          >
            <div class="provider-question__support-type d-flex flex-column">
              <p
                class="provider-question__chbx"
                :class="{ active: supportType === '1' }"
                @click="supportType = '1'"
              >
                <span
                  class="white--text d-flex"
                  :class="supportType === '1' ? 'primary--bg' : 'white--bg'"
                >
                  <span
                    class="mask-icon mask-size-lg mask-ischecked"
                  /></span>Оплата услуг
              </p>
              <p
                class="provider-question__chbx"
                :class="{ active: supportType === '2' }"
                @click="supportType = '2'"
              >
                <span
                  class="white--text d-flex"
                  :class="supportType === '2' ? 'primary--bg' : 'white--bg'"
                >
                  <span
                    class="mask-icon mask-size-lg mask-ischecked"
                  /></span>Оборудование
              </p>
              <p
                class="provider-question__chbx"
                :class="{ active: supportType === '3' }"
                @click="supportType = '3'"
              >
                <span
                  class="white--text d-flex"
                  :class="supportType === '3' ? 'primary--bg' : 'white--bg'"
                >
                  <span
                    class="mask-icon mask-size-lg mask-ischecked"
                  /></span>Не работает интернет/ТВ
              </p>
            </div>
            <d-btn
              v-show="supportType"
              class="provider-question__next title-4"
              color="custom"
              size="medium"
              @click="setNext('support')"
            >
              Далее
            </d-btn>
          </div>
          <div
            v-show="radio === 'other'"
            class="provider-question__value d-flex flex-column"
          >
            <div class="provider-question__support-type d-flex flex-column">
              <p
                class="provider-question__chbx"
                :class="{ active: otherType === '1' }"
                @click="otherType = '1'"
              >
                <span
                  class="white--text d-flex"
                  :class="otherType === '1' ? 'primary--bg' : 'white--bg'"
                >
                  <span
                    class="mask-icon mask-size-lg mask-ischecked"
                  /></span>Смена тарифа домашнего интернета (я абонент
                {{ provider.provider.name }})
              </p>
              <p
                class="provider-question__chbx"
                :class="{ active: otherType === '2' }"
                @click="otherType = '2'"
              >
                <span
                  class="white--text d-flex"
                  :class="otherType === '2' ? 'primary--bg' : 'white--bg'"
                >
                  <span
                    class="mask-icon mask-size-lg mask-ischecked"
                  /></span>Подключение ТВ или мобильной связи в мой тариф
              </p>
              <p
                class="provider-question__chbx"
                :class="{ active: otherType === '3' }"
                @click="otherType = '3'"
              >
                <span
                  class="white--text d-flex"
                  :class="otherType === '3' ? 'primary--bg' : 'white--bg'"
                >
                  <span
                    class="mask-icon mask-size-lg mask-ischecked"
                  /></span>Другое
              </p>
            </div>
            <d-btn
              v-show="otherType"
              class="provider-question__next title-4"
              color="custom"
              size="medium"
              @click="setNext('other')"
            >
              Далее
            </d-btn>
          </div>
        </div>
        <div
          v-if="segmentationActive || tempOldClient"
          v-show="nextBlock"
          class="provider-question__step2 d-flex flex-column w-100 relative"
        >
          <div class="provider-question__err">
            <p class="provider-question__err-title headline-3">
              Вы являетесь действующим абонентом {{ provider.provider.name }}
            </p>
            <p class="provider-question__err-subtitle gray-dark--text">
              Мы не сможем ответить на вопросы по действующему подключению или
              сменить ваш текущий тариф
            </p>

            <p class="title-5">
              Рекомендуем позвонить по номеру
            </p>
            <a
              :href="
                'tel:'
                  + (phone && phone.support ? phone.support.call_number : '')
              "
              class="d-inline-flex headline-3"
              @click.prevent="
                callClick(phone.support.call_number, 'callattempt_support')
              "
            >{{
              phone && phone.support && phone.support.call_number
                ? phone.support.display_number
                : "8 800 ... .. .."
            }}</a>
            <p
              v-if="
                provider?.provider?.lk_url
              "
              class="title-5"
            >
              или узнать информацию в
              <a
                class=""
                :href="provider.provider.lk_url"
                target="_blank"
              >личном кабинете</a>
            </p>
          </div>
        </div>
        <div
          v-else-if="radio === 'support' || radio === 'other'"
          v-show="nextBlock"
          class="provider-question__step2 d-flex flex-column w-100 relative"
        >
          <button
            class="d-flex align-center justify-center provider-question__back"
            @click="nextStep = false"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/arrow-right.svg"
              alt="arrow"
              style="transform: rotate(180deg); opacity: .7"
            >
          </button>
          <p class="provider-question__title headline-3">
            Остались вопросы?
          </p>
          <span class="title-2 gray-dark--text">Подключение дополнительных услуг</span>
          <form
            action="#"
            autocomplete="off"
            class="d-flex flex-column provider-question__data flex-grow"
            @submit.prevent="submit"
          >
            <div class="provider-question__inputs d-flex flex-wrap align-end">
              <div class="provider-question__input">
                <p class="provider-question__label title-5 gray-b--text">
                  Введите телефон
                </p>
                <LazyVPhoneInput
                  ref="dPhone"
                  v-model:value="phoneNumber"
                  class="w-100"
                  @validate="checkPhoneValidation($event)"
                />
              </div>
              <div class="provider-question__input">
                <p class="provider-question__label title-5 gray-b--text">
                  Введите имя
                </p>
                <LazyVTextInput
                  ref="dName"
                  v-model:value="clientName"
                  class="w-100"
                  :validation-length="0"
                  enterkeyhint="send"
                  tabindex="2"
                />
              </div>
              <d-btn
                :loading="loading"
                :disabled="!isValidPhone || !isValidName"
                class="w-100 provider-question__submit title-4"
                color="custom"
                size="medium"
                @click.prevent="submit"
              >
                Жду звонка
              </d-btn>
              <LazyVMenu
                content-class="white--bg"
                @update:model-value="showActiveSortList"
              >
                <template #activator="{ props }">
                  <span
                    v-bind="props"
                    class="provider-question__menu pointer d-flex align-center gray-b--text title-5"
                  >
                    Перезвоним {{ timeToCall }}
                    <img
                      :class="{ rotate: props['aria-expanded'] === 'true' }"
                      style="transition: transform 0.24s; width: 20px; height: 20px;"
                      loading="lazy"
                      src="@/assets/images/svg/arrow-expand.svg"
                      alt="arrow"
                    >
                  </span>
                </template>
                <LazyVList elevation="4">
                  <LazyVListItem
                    :value="timeNow"
                    :disabled="timeNow.text === timeToCall"
                    @click="setTimeToCall(timeNow)"
                  >
                    <span class="subhead-3">{{ timeNow.text }}</span>
                  </LazyVListItem>
                  <LazyVListItem
                    v-for="item in timeList"
                    :key="item.val"
                    :disabled="item.text === timeToCall"
                    @click="setTimeToCall(item)"
                  >
                    <span class="subhead-3">{{ item.text }}</span>
                  </LazyVListItem>
                </LazyVList>
              </LazyVMenu>
            </div>
            <p class="subhead-6">
              <span class="">Отправляя заявку, вы соглашаетесь</span> с
              <button
                class="secondary-dark--text"
                @click="goAway('/legal/privacy/')"
              >
                политикой обработки данных
              </button>
            </p>

            <input
              style="opacity: 0; max-height: 0"
              type="submit"
            >

            <div class="provider-question__actions d-flex" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { createInteraction } from '~/composible/createInteraction'
import type { ProviderRatingsWithAttrs } from '~/api'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { goAway } from '~/helpers'
import { callTimeComposible } from '~/composible/callTimeComposible'
import { useCities } from '~/store/cites/cities.store'
import getCallsGeneral from '~/composible/getCallsGeneral'

const props = defineProps({
  color: {},
  provider: {
    type: Object as PropType<ProviderRatingsWithAttrs>,
    required: true,
  },
})

const radio = ref('')
const radioValue = ref('')
const hType = ref('FLAT')
const supportType = ref('')
const otherType = ref('')
const nextStep = ref(false)
const nextBlock = ref(false)
const loading = ref(false)

const ctx = useNuxtApp()
const $gtm = useGtm()
const $router = useRouter()
const phoneNumber = ref('')
const clientName = ref('')
const dPhone = ref()
const dName = ref()
const phone = ref()
const isValidPhone = ref(false)
const isValidName = ref(true)
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const cityStore = useCities()
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const houseUrl = computed(() => cityStore.getHouseUrl)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const tempOldClient = ref(false)
const segmentation = computed(() => mainStore.getSegmentation)
const mainDomain = computed(() => mainStore.getMainDomain)

const { cI } = createInteraction()

const {
  timeList,
  timeNow,
  timeToCall,
  callTime,
  setTimeToCall,
  showActiveSortList,
} = callTimeComposible()

const imgUrl = (path: string, w?: number, height?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, height).buildUrl()
  return url
}

const setRadio = (val) => {
  radio.value = val
  radioValue.value = ''
  otherType.value = ''
  supportType.value = ''
}
const setNext = (val: string) => {
  if (
    val === 'support'
    || (val === 'other' && (otherType.value === '1' || otherType.value === '3'))
  ) {
    $gtm.trackEvent({
      event: 'trackEvent',
      category: 'segmentation',
      action: 'blocked',
    })
    nextBlock.value = true
    if (!interactionsSended.value) {
      mainStore.$patch({
        interactionsSended: true,
      })
      cI(
        true,
        undefined,
        { provider_id: props.provider?.provider.id },
        { request_type: 'SHOW_SUPPORT' },
        undefined,
        undefined,
      )
    }
    mainStore.$patch({
      oldClient: segmentation.value,
    })
    oldClientCookie.value = segmentation.value
    tempOldClient.value = true
    nextStep.value = true
    getPhone()
  }
  else {
    nextBlock.value = true
    nextTick(() => nextStep.value = true)
  }
}
const callClick = (number: string, action, label?) => {
  window.open('tel:' + number, '_self')
  ctx.$event('gtmSendCall', { action, label })
}

const checkPhoneValidation = (event) => {
  isValidPhone.value = event

  if (event) {
    nextTick(() => {
      dName.value.inputRef.focus()
    })
  }
}

const submit = async () => {
  if (!isValidPhone.value) {
    dPhone.value.onBlur()
    return
  }
  loading.value = true

  const tariffS = {
    provider_id: props.provider?.provider.id,
  }
  const lead = {
    phone: '+7' + phoneNumber.value.replace(/[\-(\s)]/g, ''),
    first_name: clientName.value,
    call_time: callTime.value,
  }

  const ciResp = await cI(
    !!houseUrl.value,
    lead,
    tariffS,
    radioValue.value.toUpperCase(),
    undefined,
    undefined,
    {
      house_type: radio.value === 'connect' ? hType.value : undefined,
    },
  )
  tariffsStore.$patch({
    orderId: ciResp,
    interactionId: ciResp,
  })
  loading.value = false

  nextTick(() => {
    $router.push({ path: '/complete/' })
  })
}

const getPhone = async () => {
  phone.value = await getCallsGeneral({
    provider_id: props.provider!.provider.id,
    domain: mainDomain.value,
  })
}

watch(
  () => segmentationActive.value,
  () => {
    if (segmentationActive.value) {
      nextStep.value = true
      nextBlock.value = true
      getPhone()
    }
    else {
      nextStep.value = false
      nextBlock.value = true
    }
  },
)

onMounted(() => {
  if (segmentationActive.value) {
    nextStep.value = true
    nextBlock.value = true
    getPhone()
  }
})

onBeforeMount(async () => {
  await mainStore.fetchCallSlots()
  timeToCall.value = timeNow.value.text
})
</script>

<style scoped lang="scss">
.provider-question {
  margin-bottom: 160px;
  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &__wrap {
    background: color(gray-p);
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15);
    border-radius: 16px;
    overflow-x: hidden;
    min-height: 392px;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column-reverse;
    }
  }

  &__form {
    padding: 40px 40px 8px 0;
    transition: transform 0.3s;
    width: calc(100% - 530px);
    @media (max-width: getBreakpoint(desktop)) {
      padding: 40px 24px 8px 0;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 32px 24px 8px 24px;
      width: 100%;
    }
  }

  &__step1 {
    min-width: 100%;
    margin-right: 64px;
    @media (max-width: getBreakpoint(desktop)) {
      margin-right: 48px;
    }
  }

  &__step2 {
    min-width: 100%;
    .provider-question__inputs {
      margin-bottom: auto;
    }
  }
  &__back {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: color(white);
    position: absolute;
    right: 24px;
    top: -16px;
    @media (max-width: getBreakpoint(desktop)) {
      top: -28px;
      right: -12px;
      width: 40px;
      height: 40px;
    }
    img {
      transform: rotate(180deg);
      width: 24px;
      height: 24px;
      @media (max-width: getBreakpoint(desktop)) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__img {
    min-height: 100%;
    max-width: 400px;
    border-radius: 16px 0 0 16px;
    z-index: 1;
    display: flex;
    background: color(gray-p);
    @media (max-width: getBreakpoint(tablet)) {
      height: unset;
      min-width: 100%;
      border-radius: 0 0 16px 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
    }
    @media (max-width: getBreakpoint(mobile-md)) {
    }
    img {
      height: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }

  &__radio {
    margin-top: 12px;
    @media (max-width: getBreakpoint(desktop)) {
      overflow-x: auto;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
    }
    @media (max-width: getBreakpoint(mobile-md)) {
    }
    button {
      padding: 10px 10px;
      border-radius: 28px;
      border: 1px solid var(--mainColor);
      margin-right: 8px;
      &.active {
        background: var(--mainColor);
        color: var(--font2);
      }
    }
  }

  &__value {
    //button:not(.provider-question__submit):not(.provider-question__next) {
    //  margin-right: auto;
    //  padding-left: 32px;
    //  margin-bottom: 16px;
    //  position: relative;
    //  @media (max-width: getBreakpoint(mobile-lg)) {
    //    margin-bottom: 24px;
    //  }
    //}
  }

  &__house-type {
    margin-bottom: 32px;
    overflow-x: auto;
    margin-right: -24px;
    padding-right: 24px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 28px;
    }
  }
  &__support-type {
    margin-top: 24px;
    .provider-question__chbx {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__chbx {
    flex-shrink: 0;
    position: relative;
    padding-left: 32px;
    margin-right: 16px;
    cursor: pointer;
    color: color(gray-b);
    & > span {
      position: absolute;
      border-radius: 50%;
      left: 0;
    }

    &.active {
      color: color(black);
      & > span {
        background: var(--mainColor);
        color: var(--font2);
      }
    }
    &:not(.active) {
      & > span {
        border: 1px solid color(gray-darker);
      }
    }
  }

  &__data {
    margin-top: 24px;
    width: 100%;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 8px;
    }

    & > p {
      text-align: right;
      @media (max-width: getBreakpoint(tablet)) {
        text-align: left;
      }
    }
  }

  &__inputs {
    & > p {
      align-self: center;
    }

    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      & > div {
        max-width: unset;
      }
      & > p {
        margin-bottom: 0;
        order: 2;
      }
    }
  }

  &__actions {
    justify-content: space-between;
    align-items: center;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column-reverse;
      .d-block {
        text-align: center;
      }
    }
  }

  &__label {
    margin-bottom: 4px;
  }

  .provider-question__input {
    margin-right: 8px;
    width: calc(100% / 3 - 6px);

    &:deep(.el-input__wrapper) {
      width: 100%;
      height: 56px;
      border: 1px solid color(gray-darker);
      input {
        height: 56px;
        line-height: 56px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        height: 44px;
        input {
          height: 44px;
          line-height: 44px;
        }
      }
    }
    @media (max-width: getBreakpoint(desktop)) {
      width: 100%;
      max-width: 400px;
      margin-bottom: 16px;
      margin-right: 0;
    }

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      &:deep(.el-input__wrapper) {
        height: 44px !important;
        input {
          height: 44px !important;
          line-height: 44px !important;
        }
      }
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      &:deep(.el-input__wrapper) {
        height: 40px !important;

        input {
          height: 40px !important;
          line-height: 40px !important;
        }
      }
    }
  }

  &__submit {
    height: 56px;
    width: calc(100% / 3 - 6px);
    &:not(:disabled) {
      background: var(--mainColor);
      color: var(--font2);
    }
    @media (max-width: getBreakpoint(desktop)) {
      width: 100%;
      max-width: 400px;
      margin-bottom: 16px;
      order: 2;
    }
    @media (max-width: getBreakpoint(tablet)) {
      max-width: 100%;
      width: 400px;
      margin-bottom: 12px;
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 44px !important;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px !important;
    }
  }

  &__next {
    background: var(--mainColor);
    color: var(--font2);
    padding: 16px 32px;
    margin-left: auto;
  }

  &__menu {
    display: flex;
    min-width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;
    @media (max-width: getBreakpoint(desktop)) {
      margin-top: 0;
      margin-bottom: 12px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 6px;
    }
  }

  &__err {
    @media (max-width: getBreakpoint(desktop)) {
      &-title {
        margin-right: 32px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
    }
    @media (max-width: getBreakpoint(mobile-md)) {
    }
    &-title {
      margin-bottom: 12px;
      margin-right: 64px;
    }
    &-subtitle {
      margin-bottom: 40px;
    }
    a {
      color: color(secondary-dark);
      transition: color 0.3s;
      text-decoration: none;
      margin-top: 2px;
      margin-bottom: 16px;
      &:hover {
        color: var(--mainColor);
      }
    }
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    .d-btn {
      max-height: 48px;
    }
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    .d-btn {
      max-height: 40px;
    }
  }
}
</style>
